.login__background {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__gridContentItem {
  display: flex;
  align-items: center;
}
.login__alignItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin-top: 30px;
}
.login__SIGN_IN_btn {
  width: 100%;
  margin-top: 30px;
}
.login__d-flex {
  display: flex;
}
.login__gridContentBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login__label {
  font-size: 14px;
  color: #0fbcf9;
  margin-top: 20px;
  display: block;
  padding: 10px;
  display: flex;
}
.login__input {
  border: 2px solid #bce0fd;
  border-radius: 50px;
  padding: 15px 20px;
  width: 360px;
  background: #f1f9ff 0% 0% no-repeat padding-box;
}
.login__input:focus {
  outline: none;
}
.login__input::placeholder {
  padding-bottom: 10px;
  font-size: 14px;
  color: #000;
  vertical-align: sub;
}
.login__error_msgBody {
  position: relative;
  background-color: #808e9b;
  width: 188px;
  left: 20px;
  border-radius: 10px;
}
.login__error {
  display: flex;
  align-items: flex-end;
  position: "absolute";
  right: 0;
  margin-top: 60px;
}
.login__error_text {
  font-size: 14px;
  text-align: center;
  align-content: center;
  padding: 12px 0;
}
.login__Clevrone_logo {
  width: 70%;
}
.login__password_show_btn {
  position: absolute;
  right: 13px;
  bottom: 13px;
  color: #020922;
  cursor: pointer;
}
.login_wrong_close_img {
  position: absolute;
}
.login_position_relative {
  position: relative;
}
.login_error_img_position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
